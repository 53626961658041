import { Button, Grid, Heading, Modal } from '@enterprise-ui/canvas-ui-react'

export const ConfirmActionModal = ({
  headingText,
  challengeText,
  confirmButtonText,
  confirmButtonAction,
  cancelButtonText,
  cancelButtonAction,
  isVisible,
}) => {
  return (
    <Modal
      size="dense"
      isVisible={isVisible}
      onRefuse={cancelButtonAction}
      footer={
        <Grid.Container
          spacing="dense"
          justify="flex-end"
          className="hc-pl-normal hc-pr-normal hc-pb-normal"
        >
          <Grid.Item>
            <Button type="secondary" onClick={cancelButtonAction}>
              {cancelButtonText}
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button type="primary" onClick={confirmButtonAction}>
              {confirmButtonText}
            </Button>
          </Grid.Item>
        </Grid.Container>
      }
    >
      <Grid.Container direction="column" className="hc-pa-expanded">
        <Grid.Item>
          <Heading size={3}>{headingText}</Heading>
        </Grid.Item>
        <Grid.Item>
          <p>{challengeText}</p>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}
