import { Card, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { useEffect } from 'react'
import { useAnalytics } from '@praxis/component-analytics'

const Home = () => {
  const { trackView } = useAnalytics()

  useEffect(() => {
    trackView('/', 'Homepage Load')
  }, [trackView])

  return (
    <Card className="topPage-container" data-testid="homePage">
      <Grid.Container
        justify="center"
        align="center"
        direction="column"
        className="fullHeight-container"
      >
        <Grid.Item>
          <Heading size={1}>Domestic Load Manager</Heading>
        </Grid.Item>
        <Grid.Item />
        <Grid.Item>
          <Heading size={5}>Welcome to Domestic Load Manager (DLM)</Heading>
        </Grid.Item>
        <Grid.Item>
          <Heading size={5}>
            One stop solution for all your load management needs
          </Heading>
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}

export default Home
