import { BrowserRouter as Router } from 'react-router-dom'
import { concat } from 'lodash'

import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider } from '@praxis/component-auth'
import { AnalyticsProvider } from '@praxis/component-analytics'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'

import { ProtectedElement } from '@dlm/common'
import '@dlm/common/dist/esm/css/styles.css'

import { Main } from './views/Main'

import './stylesheets/customStyles.scss'
import apiConfig from './config/apiConfig'

const App = () => {
  return (
    <ToastProvider location="top">
      <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
      <Router>
        <AuthProvider {...apiConfig.auth}>
          <AnalyticsProvider
            eventManagerConfiguration={apiConfig.analytics}
            trackLocations
          >
            <ProtectedElement
              allowed={concat(
                ...Object.values(apiConfig.auth.requiredPermissions),
              )}
            >
              <Main />
            </ProtectedElement>
          </AnalyticsProvider>
        </AuthProvider>
      </Router>
    </ToastProvider>
  )
}

export default App
