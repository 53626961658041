const currentEnv = process.env.REACT_APP_ENV

// TODO: Move to dlm-common
const featureFlag = (
  hideInEnvironment = [],
  itemToDisplay,
  replacement = null,
) => {
  if (hideInEnvironment.includes(currentEnv)) {
    return replacement
  } else {
    return itemToDisplay
  }
}

export default featureFlag
