import merge from 'lodash/merge'
import { config as analyticsConfig } from '@praxis/component-analytics'

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  auth: {
    clientId: 'dlm_npe_im',
    authorizationUrl: window.location.hostname.includes('partnersonline.com')
      ? 'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1'
      : 'https://oauth.iam.perf.target.com/auth/oauth/v2/tgt/authorize/ad-pol/1',
    logoutUrl: window.location.hostname.includes('partnersonline.com')
      ? 'https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html'
      : 'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    requiredPermissions: {
      user: ['APP-OAUTH2-MERCURY-USER-NPE'],
      admin: ['APP-OAUTH2-MERCURY-ADMIN-NPE'],
      carrier: ['APP-OAUTH2-MERCURY-CARRIER'],
      dispatcher: ['APP-OAUTH2-TDMA-DISPATCHER'],
      auction: ['APP-OAUTH2-MERCURY-AUCTION-NPE'],
      carrierEdiOnboarding: [
        'APP-OAUTH2-COS-EDI-CARRIER',
        'APP-OAUTH2-TRANS-COS-STG-USER',
        'APP-OAUTH2-TRANS-COS-STG-ADMN',
      ],
    },
  },
  api: {
    edge: 'https://mercuryedge.dev.target.com/transportation_load_details/v1',
    externalUrl: 'https://stage-api.target.com/transportation_load_details/v1',
    key: '38145752664d9d8f2893aa37558ae1c13be3671a',
  },
  analytics: {
    appName: 'Domestic Load Manager',
  },
}

const envConfigs = {
  dev: {
    mfe: {
      loadTracking: 'load_tracking@http://localhost:3001/remoteEntry.js',
      loadBoard: 'load_board@http://localhost:3002/remoteEntry.js',
      departureLog: 'departure_log@http://localhost:3003/remoteEntry.js',
      notifications: 'notifications@http://localhost:3004/remoteEntry.js',
      driverAssignment:
        'driver_assignment@http://localhost:3005/remoteEntry.js',
      carrieronboarding:
        'carrieronboarding@http://localhost:3005/remoteEntry.js',
      loadManagement: 'load_management@http://localhost:3006/remoteEntry.js',
    },
  },
  stg: {
    mfe: {
      loadTracking:
        'load_tracking@https://loadtracking.dev.target.com/remoteEntry.js',
      externalLoadTracking:
        'load_tracking@https://loadtracking.perf.partnersonline.com/remoteEntry.js',
      loadBoard: 'load_board@https://loadboard.dev.target.com/remoteEntry.js',
      externalLoadBoard:
        'load_board@https://loadboard.perf.partnersonline.com/remoteEntry.js',
      departureLog:
        'departure_log@https://departurelog.dev.target.com/remoteEntry.js',
      loadManagement:
        'load_management@https://loadmanagement.dev.target.com/remoteEntry.js',
      externalDepartureLog:
        'departure_log@https://departurelog.perf.partnersonline.com/remoteEntry.js',
      notifications:
        'notifications@https://notifications.dev.target.com/remoteEntry.js',
      externalNotifications:
        'notifications@https://notifications.perf.partnersonline.com/remoteEntry.js',
      driverAssignment:
        'driver_assignment@https://driverassignment.dev.target.com/remoteEntry.js',
      externalDriverAssignment:
        'driver_assignment@https://driverassignment.perf.partnersonline.com/remoteEntry.js',
      carrieronboarding:
        'carrieronboarding@https://carrieronboarding.dev.target.com/remoteEntry.js',
      externalCarrieronboarding:
        'carrieronboarding@https://carrieronboarding.perf.partnersonline.com/remoteEntry.js',
    },
    analytics: {
      apiKey: '38145752664d9d8f2893aa37558ae1c13be3671a',
      url: analyticsConfig.api.internal.stage,
    },
  },
  prod: {
    auth: {
      // keys in envConfigs will overwrite keys from commonConfig
      authorizationUrl: window.location.hostname.includes('partnersonline.com')
        ? 'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1'
        : 'https://oauth.iam.target.com/auth/oauth/v2/tgt/authorize/ad-pol/1',
      logoutUrl: window.location.hostname.includes('partnersonline.com')
        ? 'https://oauth.iam.partnersonline.com/login/responses/logoff.html'
        : 'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'dlm_prod_im',
      requiredPermissions: {
        user: ['APP-OAUTH2-MERCURY-USER'],
        admin: ['APP-OAUTH2-MERCURY-ADMIN'],
        carrier: ['APP-OAUTH2-MERCURY-CARRIER'],
        auction: ['APP-OAUTH2-MERCURY-AUCTION'],
        carrierEdiOnboarding: [
          'APP-OAUTH2-TRANS-COS-PRD-ADMN',
          'APP-OAUTH2-COS-EDI-CARRIER',
        ],
      },
    },
    mfe: {
      loadTracking:
        'load_tracking@https://loadtracking.prod.target.com/remoteEntry.js',
      externalLoadTracking:
        'load_tracking@https://loadtracking.partnersonline.com/remoteEntry.js',
      loadBoard: 'load_board@https://loadboard.prod.target.com/remoteEntry.js',
      externalLoadBoard:
        'load_board@https://loadboard.partnersonline.com/remoteEntry.js',
      departureLog:
        'departure_log@https://departurelog.prod.target.com/remoteEntry.js',
      loadManagement:
        'load_management@https://loadmanagement.prod.target.com/remoteEntry.js',
      externalDepartureLog:
        'departure_log@https://departurelog.partnersonline.com/remoteEntry.js',
      notifications:
        'notifications@https://notifications.prod.target.com/remoteEntry.js',
      externalNotifications:
        'notifications@https://notifications.partnersonline.com/remoteEntry.js',
      driverAssignment:
        'driver_assignment@https://driverassignment.prod.target.com/remoteEntry.js',
      externalDriverAssignment:
        'driver_assignment@https://driverassignment.partnersonline.com/remoteEntry.js',
      carrieronboarding:
        'carrieronboarding@https://carrieronboarding.prod.target.com/remoteEntry.js',
      externalCarrieronboarding:
        'carrieronboarding@https://carrieronboarding.partnersonline.com/remoteEntry.js',
    },
    api: {
      edge: 'https://mercuryedge.prod.target.com/transportation_load_details/v1',
      externalUrl: 'https://api.target.com/transportation_load_details/v1',
      key: '56700114104fa91df4c4d240a82a348cbc11c762',
    },
    analytics: {
      apiKey: '56700114104fa91df4c4d240a82a348cbc11c762',
      url: analyticsConfig.api.internal.prod,
    },
  },
}

export const setMoreConfigs = (hostname) => {
  if (hostname.includes('partnersonline.com')) {
    commonConfig.api.edge = commonConfig.api.externalUrl
    envConfigs.prod.api.edge = envConfigs.prod.api.externalUrl
    envConfigs.stg.mfe.loadTracking = envConfigs.stg.mfe.externalLoadTracking
    envConfigs.stg.mfe.loadBoard = envConfigs.stg.mfe.externalLoadBoard
    envConfigs.stg.mfe.notifications = envConfigs.stg.mfe.externalNotifications
    envConfigs.stg.mfe.driverAssignment =
      envConfigs.stg.mfe.externalDriverAssignment
    envConfigs.stg.mfe.departureLog = envConfigs.stg.mfe.externalDepartureLog
    envConfigs.stg.mfe.carrieronboarding =
      envConfigs.stg.mfe.externalCarrieronboarding
    envConfigs.stg.analytics.url =
      'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app'
    envConfigs.prod.mfe.loadTracking = envConfigs.prod.mfe.externalLoadTracking
    envConfigs.prod.mfe.loadBoard = envConfigs.prod.mfe.externalLoadBoard
    envConfigs.prod.mfe.notifications =
      envConfigs.prod.mfe.externalNotifications
    envConfigs.prod.mfe.driverAssignment =
      envConfigs.prod.mfe.externalDriverAssignment
    envConfigs.prod.mfe.departureLog = envConfigs.prod.mfe.externalDepartureLog
    envConfigs.prod.mfe.carrieronboarding =
      envConfigs.prod.mfe.externalCarrieronboarding
    envConfigs.prod.analytics.url =
      'https://api.target.com/consumers/v1/ingest/internal/internal_app'
  }
}

setMoreConfigs(window.location.hostname)

const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config)

export default apiConfig
