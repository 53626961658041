import {
  Layout,
  Grid,
  Card,
  Heading,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { Announcement } from './components/Announcement/Announcement'

export const Admin = () => {
  return (
    <Layout.Body data-testid="admin" includeRail>
      <Grid.Container>
        <Grid.Item xs={12}>
          <Card className="hc-pa-expanded">
            <Grid.Container>
              <Grid.Item>
                <Heading size={3}>Admin Controls</Heading>
              </Grid.Item>

              <Grid.Item xs={12}>
                <Tabs activeTab="banner">
                  <Tabs.Item name="banner">Announcement Banner</Tabs.Item>
                </Tabs>
              </Grid.Item>

              <Grid.Item xs={12}>
                <Announcement />
              </Grid.Item>
            </Grid.Container>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}
