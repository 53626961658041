import { Link } from 'react-router-dom'
import {
  Button,
  Grid,
  Heading,
  Layout,
  TargetLogo,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { MenuIcon, LogoutIcon } from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'
import { getAdmin, getUserCompany } from '../service/dlmService'
import { useState, useEffect } from 'react'
import { ConfirmActionModal } from './ConfirmActionModal'
import { processAnnouncementMessage } from '../views/Admin/components/Announcement/Announcement'

export const Header = ({ onSideNavOpen }) => {
  const auth = useAuth()
  const { logout, session } = auth
  const userId = session?.userInfo?.lanId
  const [company, setCompany] = useState('')
  const [messageData, setMessageDataData] = useState({
    message: '',
    message_type: '',
  })
  const [isNotificationOpen, setIsNotificationOpen] = useState(true)

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false)

  const openLogoutModal = () => {
    setIsLogoutModalVisible(true)
  }

  const closeLogoutModal = () => {
    setIsLogoutModalVisible(false)
  }

  useEffect(() => {
    if (!userId || userId.startsWith('Z')) {
      setCompany('')
    } else {
      getUserCompany(userId)
        .then((resp) => {
          setCompany(resp.business_partner_name)
        })
        .catch(() => {
          setCompany('Unknown Company')
        })
    }
  }, [userId])

  useEffect(() => {
    getAdmin().then((data) => setMessageDataData(data))
  }, [])

  return (
    <>
      <ConfirmActionModal
        headingText="Are you sure you want to logout?"
        challengeText="Unsaved changes will be lost."
        confirmButtonText="Logout"
        confirmButtonAction={logout}
        cancelButtonText="Cancel"
        cancelButtonAction={closeLogoutModal}
        isVisible={isLogoutModalVisible}
      />

      {messageData.message.length > 0 && isNotificationOpen && (
        <Layout.Notification
          type={messageData.message_type}
          actions={[
            { text: 'Close', onClick: () => setIsNotificationOpen(false) },
          ]}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: processAnnouncementMessage(messageData.message),
            }}
          />
        </Layout.Notification>
      )}

      <Layout.Header className="topPage-layout-header" includeRail={true}>
        <Grid.Container justify="space-between">
          <Grid.Item>
            <Grid.Container spacing="dense">
              <Grid.Item>
                <Button
                  className="C-MenuButton headerIconButton"
                  onClick={onSideNavOpen}
                  iconOnly
                  data-testid="headerMenuButton"
                  aria-label="open side nav menu"
                >
                  <EnterpriseIcon icon={MenuIcon} size="lg" />
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Link to="/">
                  <TargetLogo size="expanded" color="white" />
                </Link>
              </Grid.Item>
              <Grid.Item>
                <Link to="/">
                  <Heading size={4} className="white-header">
                    Domestic Load Manager
                  </Heading>
                </Link>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container>
              <Layout.GlobalActions>
                <Grid.Container justify="flex-end" align="center">
                  <Grid.Item>
                    <Grid.Container direction="column" align="flex-end">
                      <p className="hc-fs-md user-info">
                        {session.userInfo.fullName}
                      </p>
                      <p className="hc-fs-xs user-info">{company}</p>
                    </Grid.Container>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      aria-label="Click to logout"
                      iconOnly
                      data-testid="headerLogoutButton"
                      className="headerIconButton"
                      onClick={openLogoutModal}
                    >
                      <EnterpriseIcon icon={LogoutIcon} size="md" />
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </Layout.GlobalActions>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
    </>
  )
}
