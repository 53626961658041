import axios from 'axios'
import apiConfig from '../config/apiConfig'

export async function getUserCompany(userId) {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/business_partner_scacs/${userId}`,
    {
      headers: {
        'x-api-key': apiConfig.api.key,
      },
    },
  )
  return data
}

export async function updateAdmin(payload) {
  await axios.put(`${apiConfig.api.edge}/admin/dlm`, payload, {
    headers: {
      'x-api-key': apiConfig.api.key,
    },
  })
}

export async function getAdmin() {
  const { data } = await axios.get(`${apiConfig.api.edge}/admin/dlm`, {
    headers: {
      'x-api-key': apiConfig.api.key,
    },
  })
  return data
}
